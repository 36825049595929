import React from 'react';

import Jumbotron from 'react-bootstrap/Jumbotron';
import { Navbar, Nav } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';
import Footer from '../../components/Footer';

function Home() {
    const address1 = '2028 E Ben White Blvd';
    const address2 = '#240-5729';
    const city = 'Austin';
    const state = 'TX';
    const zip = 78741;

    const addressURI = encodeURI(`${address1} ${address2} ${city}, ${state} ${zip}`);
    const googleAPIKey = 'AIzaSyD9CcPSHG2qgkF5AsXvmXk3dhM5lMUJR38';

    return (
        <>
            <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand href="#"><img src="Ko-Law-Firm_4C-PLLC.png" alt="Ko Law Firm Logo" className='img-responsive' /></Navbar.Brand>
                    <Nav>
                        <Nav.Link href="#practice-areas">Practice Areas</Nav.Link>
                        <Nav.Link href="#our-team">Our Team</Nav.Link>
                        <Nav.Link href="#contact">Contact</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>

            <Jumbotron>
                <Container className='text-center'>
                    <Row>
                        <Col>
                            <h1 className='font-weight-bold'>The Ko Law Firm, PLLC</h1>
                            <p>The Ko Law Firm, PLLC, is a full-service, client-centered law firm focused on Austin's vibrant community of small and family-owned businesses, entrepreneurs, and start-ups. From photographers to food trucks to affordable housing developments, we help people build ideas into businesses, protect their labor and investments, resolve legal disputes, and protect their rights. We also assist with common legal needs that any of us might encounter, such as finally getting that will written, getting through an amicable, but still difficult divorce, or dealing with potential criminal allegations.</p>
                            <p>We are committed to providing personal, responsive service tailored to the needs of each client. We are mindful of the challenges that families and small businesses face, so we strive for efficient, cost-effective solutions to achieve client goals. With over three decades of combined legal experience, our team is here for your transactional and litigation needs.</p>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <section id='languages' className='bg-success py-4'>
                <Container className='text-center'>
                    <Row>
                        <Col>
                            <p><strong>In addition to English, our attorneys speak Mandarin Chinese and Spanish.</strong></p>
                            <p className='text-big'><strong>我们提供中文服务 <span className="blue-line">|</span> Se habla español.</strong></p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id='covid-notice' className='bg-danger py-4 mb-4'>
                <Container className='text-center'>
                    <Row>
                        <Col>
                            <p className='mb-2'><strong>COVID-19 Notice: WE ARE OPEN FOR BUSINESS.</strong></p>
                            <p className='mb-0 text-small'>Due to the COVID-19 pandemic, our office is temporarily closed to the public, but our attorneys are ready and available to assist with your legal needs. You can still reach us by phone and e-mail, and we are happy to meet virtually as well. If meeting in person is unavoidable, all parties will observe appropriate precautions, include social distancing and appropriate face coverings.</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section id='practice-areas' className='mb-5'>
                <Container>
                    <h2 className='font-weight-bold text-center mb-3'>Main Practice Areas</h2>

                    <Row className='mb-4'>
                        <Col md={6} className='mb-4'>
                            <div>
                                <h3 className='font-weight-bold'>Business</h3>
                                <p>We offer a broad range of services to support businesses of all kinds, including extensive experience with small and newer businesses.</p>
                                <ul>
                                    <li>Business entity formation</li>
                                    <li>Company and asset sales and purchases</li>
                                    <li>Contract review, drafting, and negotiation</li>
                                    <li>Business counseling and compliance</li>
                                    <li>Employment and licensing matters</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={6} className='mb-4'>
                            <div>
                                <h3 className='font-weight-bold'>Real Estate</h3>
                                <p>Austin is a dynamic real estate market with many investors and projects, large and small, including many of our small business and entrepreneur clients. We handle a variety of real estate matters, including:</p>
                                <ul>
                                    <li>Project-based business and investor agreements</li>
                                    <li>Commercial and residential real estate sales and purchases</li>
                                    <li>Commercial and residential leasing and landlord-tenant disputes</li>
                                    <li>Asset and liability protection</li>
                                    <li>Real estate holding and investment company formation</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>

                    <Row className='minor'>
                        <Col md={6} lg={3} className='mb-4'>
                            <div>
                                <h4 className='font-weight-bold'>Immigration</h4>
                                <p>Austin and Texas attract a large share of hardworking and creative immigrants to the United States. We help unite families and find ways for talented individuals to make a new life and become part of our community by representing clients in marriage and family-based green card applications.</p>
                            </div>
                        </Col>
                        <Col md={6} lg={3} className='mb-4'>
                            <div>
                                <h4 className='font-weight-bold'>Probate, Wills &amp; Estates</h4>
                                <p>Wealthy people are not the only ones that need to plan for their legacy. Having a plan in place and drafting a will can help minimize the legal burden and provide for your family in case of emergency. In our uncertain times, estate planning is an important responsibility that should be taken seriously. We can draft wills, assist with disability and end-of-life planning, and represent clients in probate proceedings in court, including Determinations of Heirship when someone passes without a will. <em className='text-small'>*We do not provide estate tax planning services.</em></p>
                            </div>
                        </Col>
                        <Col md={6} lg={3} className='mb-4'>
                            <div>
                                <h4 className='font-weight-bold'>Family</h4>
                                <p>Deciding to end a marriage can be legally confusing and intimidating while already facing a difficult emotional situation, but many divorces can be resolved in a sensitive and amicable manner. We help clients navigate uncontested divorces and reach agreements that protect their rights while minimizing conflict. We also offer representation for marital property agreements (prenups and postnups). For more complex and contested matters, we work with other family attorneys to help get clients the appropriate representation for their needs.</p>
                            </div>
                        </Col>
                        <Col md={6} lg={3} className='mb-4'>
                            <div>
                            <h4 className='font-weight-bold'>Criminal</h4>
                            <p>Unfortunate circumstances, a misunderstanding, or a simple mistake can result in law-abiding and responsible citizens facing criminal charges. Whether you are accused of a misdemeanor or a felony, our criminal defense counsel brings years of experience as a prosecutor and defense attorney and over a hundred cases tried to fight for you and navigate the challenges of the criminal justice system.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section id='our-team'>
                <Container fluid>
                    <h2 className='font-weight-bold text-center'>Our Team</h2>
                    <Row className='p-4'>
                        <Col xs={{span: 12, order: 2}} md={8} lg={9} className='align-self-center'>
                            <div>
                            <h3 className='font-weight-bold'>Ramey Ko</h3>
                            <h4 className='font-italic'>Principal Attorney</h4>
                            <p>Ramey Ko is the principal attorney and founder of The Ko Law Firm, PLLC. Since beginning his legal career in Austin in 2006, Ramey has amassed diverse experience as a civil litigator, a transactional attorney, a criminal judge, and a university lecturer.</p>
                            <p>After graduating from Yale University and the University of Chicago Law School, Ramey began practicing as a legal services attorney with Texas RioGrande Legal Aid and later the Texas Advocacy Project, providing free representation and advice to low-income tenants and survivors of family violence, sexual assault, and stalking. In 2010, Ramey Ko became the first Asian American judge in Austin when he was appointed an Associate Municipal Judge and served in that position through 2013, presiding over both bench and jury trials for Class C misdemeanors and reviewing arrest and search warrants as a criminal magistrate. In addition to his attorney and judicial responsibilities, Ramey has taught classes in law at the University of Texas at Austin and the MBA program at Texas Health &amp; Science University.</p>
                            <p>Moving to private practice in 2012, Ramey's unique and wide-ranging experience has enabled him to provide a broad range of legal services to a diverse clientele. Handling both litigation and business transactions, Ramey Ko represents and advises numerous small and family-owned businesses and start-ups that appreciate personalized and efficient service at a reasonable cost. As one of the only Mandarin speaking attorneys in Central Texas, Ramey has also assisted numerous clients on immigration, family, wills, estates, and probate, and criminal matters.</p>
                            <p>Ramey is a passionate citizen and volunteer, serving or having served on numerous nonprofit boards including the Austin Asian Community Health Initiative, Six Square: Austin's Black Cultural District, and GlobalAustin. He also previously chaired the City of Austin Commission on Immigrant Affairs and was an inaugural member of the Austin Public Safety Commission when it was created to advise the city council on matters related to the police, fire, and EMS departments. From 2010 to 2014, Ramey had the honor and privilege of serving as an appointee of the President of the United States to the President's Advisory Commission on Asian Americans &amp; Pacific Islanders.</p>
                            <p>Ramey is an avid cook and PC and tabletop gamer. He lives with his wife, Lauren, and their dogs, Mycroft, Peanut, and Dr. Jill Biden.</p>
                            </div>
                        </Col>
                        <Col md={{ order: 2 }} className='align-self-center'>
                            <img src='img/Ramey-portrait_300px.jpg' alt='Ramey Ko' className='img-fluid w-100 rounded-circle mb-3' />
                        </Col>
                    </Row>
                    <Row className='p-4'>
                        <Col xs={{ span: 12, order: 2 }} md={8} lg={9} className='align-self-center'>
                            <h3 className='font-weight-bold'>Rick Cofer</h3>
                            <h4 className='font-italic'>Of Counsel</h4>
                            <p>Austin criminal defense attorney Rick Cofer has extensive experience in criminal and juvenile cases. Licensed to practice law in 2008, Rick has worked as a defense attorney and prosecutor, resolving thousands of cases and trying over one hundred cases to a judge or jury.</p>
                            <p>A graduate of the University of Texas at Austin and University of Texas School of Law, Rick Cofer was a defense attorney before serving over eight years as a Travis County Assistant County Attorney and Travis County Assistant District Attorney handling DWI, assault, family domestic violence, drugs, sex crimes, property crimes, juvenile charges and murder cases.</p>
                            <p>Rick Cofer has been admitted to the State Bar of Texas, U.S. Supreme Court, U.S. Court of Appeals for the Fifth Circuit, and U.S. District Court for the Western District of Texas. He is a member of the Austin Bar Association and has served as Treasurer of the Criminal Law Section since 2014. Rick is also a member of the Texas Bar College, Texas Criminal Defense Lawyers Association, Capital Area Trial Lawyers Association, and Austin Criminal Defense Lawyers Association.</p>
                            <p>Rick is active in the Austin Community serving on the board of directors of the KIND Clinic and Ending Community Homelessness Coalition (ECHO). He previously served as chair of the City of Austin Zero Waste Commission, vice chair of the City of Austin Parks &amp; Recreation Board, and president of the Pease Park Conservancy Board of Directors.</p>
                        </Col>
                        <Col md={{ order: 1 }} className='align-self-center'>
                            <img src='img/Rick-portrait_300px.jpg' alt='Rick Coffer' className='img-fluid w-100 rounded-circle mb-3' />
                        </Col>
                    </Row>
                    <Row className='p-4'>
                        <Col xs={{span: 12, order: 2}} md={8} lg={9} className='align-self-center'>
                            <div>
                            <h3 className='font-weight-bold'>Cora Y. Ying</h3>
                            <h4 className='font-italic'>Legal Assistant</h4>
                            <p>Cora Ying provides administrative and paralegal services to the attorneys and staff of The Ko Law Firm, PLLC. Cora brings extensive experience in law firm administration and accounting and speaks English, Mandarin Chinese, and Spanish, enabling us to be more accessible and transparent to clients. Cora earned her BA in Business Administration from Hangzhou University of Commerce and her Master’s in Education from Tecnológico de Monterrey (ITESM), Campus Guadalajara, Mexico. Cora is based in the Dallas-Ft. Worth Metroplex and lives with her husband Sam and their two boys.</p>
                            </div>
                        </Col>
                        <Col md={{ order: 2 }} className='align-self-center'>
                            <img src='img/Cora-portrait_300px.jpg' alt='Cora Y. Ying' className='img-fluid w-100 rounded-circle mb-3' />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section id="contact" className='mb-0 pt-4'>
                <Container>
                    <h2 className='font-weight-bold'>Contact</h2>

                    <Row>
                        <Col className='pt-4 contact-info'>
                            <address>
                                <h4>Mailing Address:</h4>
                                {address1} {address2}<br />
                                {city}, {state} {zip}
                            </address>

                            <address>
                                <a href='mailto:cora@thekolawfirm.com'>cora@thekolawfirm.com</a>
                            </address>

                            <address>
                                <strong>(O)</strong> 512.794.9200<br />
                                <strong>(F)</strong> 512.419.9293
                            </address>
                        </Col>
                        <Col sm={8}>
                            <ResponsiveEmbed aspectRatio="16by9" className='mx-sm-4'>
                                <embed src={`https://www.google.com/maps/embed/v1/place?key=${googleAPIKey}&q=${addressURI}`} />
                            </ResponsiveEmbed>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer />
        </>
    )
}

export default Home